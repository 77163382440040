import Link from 'next/link'
import React from 'react'

import { LazyImage } from '../lazy-image'

type Props = {
  locationName: string
  featureImage: string
  logoImage: string
}

export const Hero = ({ locationName, featureImage, logoImage }: Props) => {
  return (
    <div className="w-full">
      <Link href={`/menu/`}>
        <div className="flex relative justify-center items-center h-52 sm:h-72 md:h-60">
          {featureImage ? (
            <LazyImage src={featureImage} alt={''} />
          ) : (
            <div className="h-full w-full object-cover" />
          )}
          {logoImage ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              className="absolute justify-center items-center max-h-[120px] max-w-[240px]"
              src={logoImage}
              alt={''}
            />
          ) : (
            <div className="text-4xl text-lg-bold text-center text-white absolute justify-center items-center max-w-[240px]">
              {locationName}
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}
