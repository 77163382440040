import Image, { ImageProps } from 'next/image'
import React, { useState } from 'react'
import { Blurhash } from 'react-blurhash'

const defaultBlurHash = 'L6PZfSjE.AyE_3t7t7R**0o#DgR4'
type Props = {
  placeholderHash?: string
  classNames?: string
} & ImageProps

export const LazyImage = ({
  placeholderHash = defaultBlurHash,
  classNames = '',
  src = '',
  alt = '',
  priority = false,
  ...rest
}: Props) => {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)

  const handleImageLoad = () => {
    setIsLoadingComplete(true)
  }

  return (
    <div className={`w-full h-full relative ${classNames}`}>
      <Image
        {...rest}
        className="object-cover"
        src={src}
        alt={alt}
        onLoadingComplete={handleImageLoad}
        fill={true}
        priority={priority}
        loading={priority ? undefined : 'lazy'}
      />

      <div
        className={`col-span-full absolute inset-0 -transition-opacity duration-100 ${
          isLoadingComplete ? 'opacity-0' : 'opacity-100'
        }`}>
        <Blurhash
          hash={placeholderHash}
          width="100%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
    </div>
  )
}
